import { SubscriptionInfo } from "../../app/types";

function getPlanLabel(plan: "freeTier" | "tier1" | "tier2" | "tier3") {
  switch (plan) {
    case "freeTier":
      return "Free Plan";
    case "tier1":
      return "Private Plan";
    case "tier2":
      return "Sharing Plan";
    case "tier3":
      return "Pro Plan";
  }
}

export class ShortUserInfo extends HTMLElement {
  shadow: ShadowRoot | undefined;

  menuOpen: boolean = false;
  subscriptionInfo: SubscriptionInfo | null = null;

  constructor() {
    super();
  }

  getTemplate() {
    // openMenu is used in media query less  mode to display or not the vertical menu
    // In the future it will work also for the desktop menu, giving more options (like Amazon.com user button)
    return `
<template>
    <style>
    :host{
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-right: 2rem;
        flex:1;
    }
    .menu-button{
        display:flex;
        flex-direction: column;
        font-size:14px;
        margin-left: 1rem
    }
    .right > * {
        margin-left: 1rem;
    }
     a {
      text-decoration: none;
      color: #232f3e;
      padding-left:2rem;
      padding-right:2rem;
    }
     
      a:visited{
          color:#232f3e;
      }
     a:hover{
          background-color: #f5f5f5;
          color: #0284C7;
      }    .name {
        
    }
    @media (max-width:800px) {
        :host{
            margin-right: 0px;
        }
        .right{
            display: none;
        }
        .right.open{
            display: flex !important;
            position: absolute;
            z-index: 10;
            background: #232f3e;
            top: 4rem;
            flex: ;
            flex-direction: column;
            width: 100%;
            color: white;
            padding-top: 1rem;
            justify-content: start;
            margin-left:0px;
        }
        .right.open > a{
            color: white;
            height:2rem;
            padding: 0.25rem 0.25rem 0.25rem 1rem;
            margin-left: 0px;
        }
        .right.open > a:last-child{
            padding-bottom: 8rem;
        }
        .name::after{
            content: "${this.menuOpen ? " ▲" : " ▼"}";
            font-size:10px;
        }
    }
    </style>
    ${
      this.subscriptionInfo
        ? `<div class="right${this.menuOpen ? " open" : ""}">
              ${
                this.subscriptionInfo.usertype === "freeTier" ||
                this.subscriptionInfo.usertype === "tier1"
                  ? `<a href="/app/#/subscribe">Upgrade</a>`
                  : `<a href="/app/#/subscribe">Your account</a>`
              }
             
              <a href="/app/">Your notes</a>
            </div>
            <div class="menu-button">
              <div class="name">
                   Hello, ${this.subscriptionInfo.displayName.split(" ")[0]}
                   </div>
                   <div class="plan">
                      ${getPlanLabel(this.subscriptionInfo.usertype)}
                   </div>
            </div>`
        : `<a href="/app/">Login</a>`
    }   
</template>
`;
  }

  onClick(el: Event | null) {
    const target: any = el?.target;
    if (!target && !(target instanceof Element)) {
      return;
    }
    const t = target as Element;
    if (t.parentElement && t.parentElement.className === "menu-button") {
      this.menuOpen = !this.menuOpen;
    }
    const shEl = this.createElement();
    this.shadow?.replaceChildren(shEl);
  }

  onBlur() {
    this.menuOpen = false;
  }

  createElement() {
    const subscriptionInfoStr = localStorage.getItem("subscriptionInfo");
    try {
      const subscriptionInfo: SubscriptionInfo | null =
        subscriptionInfoStr && JSON.parse(subscriptionInfoStr);
      this.subscriptionInfo = subscriptionInfo;
    } catch (err: any) {
      console.error("Error when parsing JSON", err);
    }
    const el = document.createElement("div");
    el.innerHTML = this.getTemplate();
    return (el.firstElementChild as HTMLTemplateElement).content.cloneNode(
      true,
    );
  }

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });
    shadow.appendChild(this.createElement()!);
    shadow.addEventListener("click", (ev) => this.onClick(ev));
    shadow.addEventListener("blur", (ev) => {
      console.log("blur");
      console.log(ev.target);
    });
    this.shadow = shadow;
  }
}

customElements.define("short-user-info", ShortUserInfo);
