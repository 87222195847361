import { detect } from "detect-browser";

export class AddToBrowserButton extends HTMLElement {
  createElement() {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      // This checks if the current device is in a mobile device
      return null; // no extensions for mobile browsers (yet)
    }
    const browser = detect();
    let innerHTML = "";
    let buttonName: string | undefined = undefined;
    let extensionUrl: string | undefined = undefined;
    if (browser) {
      if (browser.name === "chrome") {
        buttonName = "Chrome";
        extensionUrl =
          "https://chromewebstore.google.com/detail/mark-and-note/glknaloegapcddfimfmfmcmipgmophpb";
      } else if (browser.name === "edge" || browser.name === "edge-chromium") {
        buttonName = "Edge";
        extensionUrl =
          "https://chromewebstore.google.com/detail/mark-and-note/glknaloegapcddfimfmfmcmipgmophpb";
      } else if (browser.name === "firefox") {
        buttonName = "Firefox";
        extensionUrl =
          "https://addons.mozilla.org/en-US/firefox/addon/mark-and-note"; //TODO change this when approved in Firefox
      }
    }
    const el = document.createElement("div");
    if (!buttonName) {
      innerHTML = `
        <template>
          <style>
            :host{
                max-width: 300px;
                font-weight: normal;
                margin-bottom: 1em;
                font-size: 16px;
            }
           </style>
         <div>Your browser isn't supported yet. You can use Chrome or Edge for now</div>
        </template>`;
      el.innerHTML = innerHTML;
    } else {
      innerHTML = `
      <template>
        <style>
            :host {
                width: 300px;
                height: 80px;
                display: flex;
                background-color: #4286F5;
                color: white;
                border-radius: 50px;
                font-size: 26px;
                margin-bottom: 0.5em;
                margin-top: 1em;
            }
            :host div {
                justify-content: center;
                align-self: center;
                width: 100%;
                text-align: center;
            }
            a {
                text-decoration: none;
                color:white;
            }
            a:visited {
                color:white;
            }
        </style>
            <div>
                <a href="${extensionUrl}">Download for ${buttonName}</a>
            </div>
        </template>
        `;
      el.innerHTML = innerHTML;
    }
    const buttonNode = (
      el.firstElementChild as HTMLTemplateElement
    ).content.cloneNode(true);
    return buttonNode;
  }

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });
    shadow.appendChild(this.createElement()!);
  }
}

customElements.define("add-to-browser", AddToBrowserButton);
